/* root styles */

:root {
  --primary: #f46b30;
  --secondary: rgba(244, 107, 48, 0.2);
  --grey-medium: #c4c4c4;
  --grey-light: #e5e5e5;
  --grey-ultra-light: #f1f1f1;

  /* Breakpoint variables */
  --bp-sm: 36.15;
  --bp-md: 48.75;
  --bp-lg: 62.25;
  --bp-xl: 75;
  --bp-xxl: 87.5;

  /* Paragraph variables */
  --p-font-size-min: 1;
  --p-font-size-max: 1.25;
  --p-line-height-min: 1.15;
  --p-line-height-max: 1.5;
}

/* webfonts styles */

@font-face {
  font-family: 'Volte';
  src: url(../fonts/Volte/WOFF/Volte-Semibold.woff) format('woff'),
    url(../fonts/Volte/WOFF2/Volte-Semibold.woff2) format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Volte';
  src: url(../fonts/Volte/WOFF/Volte-SemiboldItalic.woff) format('woff'),
    url(../fonts/Volte/WOFF2/Volte-SemiboldItalic.woff2) format('woff2');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url(../fonts/SFProDisplay/WOFF/SFProDisplay-Regular.woff) format('woff'),
    url(../fonts/SFProDisplay/WOFF2/SFProDisplay-Regular.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url(../fonts/SFProDisplay/WOFF/SFProDisplay-RegularItalic.woff)
      format('woff'),
    url(../fonts/SFProDisplay/WOFF2/SFProDisplay-RegularItalic.woff2)
      format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url(../fonts/SFProDisplay/WOFF/SFProDisplay-SemiBold.woff) format('woff'),
    url(../fonts/SFProDisplay/WOFF2/SFProDisplay-SemiBoldItalic.woff2)
      format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url(../fonts/SFProDisplay/WOFF/SFProDisplay-RegularItalic.woff)
      format('woff'),
    url(../fonts/SFProDisplay/WOFF2/SFProDisplay-RegularItalic.woff2)
      format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url(../fonts/SFProDisplay/WOFF/SFProDisplay-Bold.woff) format('woff'),
    url(../fonts/SFProDisplay/WOFF2/SFProDisplay-Bold.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url(../fonts/SFProDisplay/WOFF/SFProDisplay-BoldItalic.woff)
      format('woff'),
    url(../fonts/SFProDisplay/WOFF2/SFProDisplay-BoldItalic.woff2)
      format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

/* base styles */

html {
  font-size: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
}

body {
  color: #000;
  background-color: #fff;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  line-height: 1.2;
  font-style: normal;
  font-family: 'SF Pro Display', 'SF UI Text', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

*,
*::before,
*::after {
  border: 0;
  outline: 0;
  color: inherit;
  font-size: 100%;
  line-height: inherit !important;
  font-style: inherit;
  font-family: inherit;
  box-sizing: inherit;
  vertical-align: baseline;
}

/* responsive typography */

/* p {
  font-size: calc(var(--p-font-size-min) * 1rem);
} */

/* @media screen and (min-width: 36.15em) {
  p {
    font-size: calc(
      (var(--p-font-size-min * 1rem)) +
        (var(--p-font-size-max) - var(--p-font-size-min)) *
        ((100vw - (var(--bp-sm) * 1rem)) / (var(--bp-lg) - var(--bp-sm)))
    );
  }
} */

/* @media screen and (min-width: 87.5em) {
  p {
    font-size: calc(var(--p-font-size-max) * 1rem);
  }
} */

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* form input base styles */

input {
  font-size: inherit !important;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-size: 14px !important;
}

input::-webkit-inner-spin-button {
  display: none !important;
}

b {
  font-weight: 700;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.page-wrapper {
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (min-width: 1024px) {
  .page-wrapper {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

.page-header {
  margin-top: calc(4px * 11);
}

.page-heading {
  font-size: 1.25em;
  font-weight: 500;
  margin-bottom: 0;
}

.table-wrapper {
  min-height: 40vh;
  margin-bottom: 2.4rem;
  position: relative;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}
